<script>
  import {
    layoutComputed
  } from "@/state/helpers";
  
  export default {
    components: {
      
    },
    data() {
      return {
        settings: {
          minScrollbarLength: 60,
        },
        options: []
      };
    },
    computed: {
      ...layoutComputed,
      layoutType: {
        get() {
          return this.$store ? this.$store.state.layout.layoutType : {} || {};
        },
      },
    },

    watch: {
      $route: {
        handler: "onRoutechange",
        immediate: true,
        deep: true,
      },
    },

    mounted() {

      this.options=JSON.parse(localStorage.getItem('dataMenu'));
      if (document.querySelectorAll(".navbar-nav .collapse")) {
        let collapses = document.querySelectorAll(".navbar-nav .collapse");
        collapses.forEach((collapse) => {
          // Hide sibling collapses on `show.bs.collapse`
          collapse.addEventListener("show.bs.collapse", (e) => {
            e.stopPropagation();
            let closestCollapse = collapse.parentElement.closest(".collapse");
            if (closestCollapse) {
              let siblingCollapses =
                closestCollapse.querySelectorAll(".collapse");
              siblingCollapses.forEach((siblingCollapse) => {
                if (siblingCollapse.classList.contains("show")) {
                  let aChild = siblingCollapse.parentNode.firstChild;
                  if (aChild.hasAttribute("aria-expanded")){
                    aChild.setAttribute("aria-expanded", "false");
                  }
                  siblingCollapse.classList.remove("show");
                }
              });
            } else {
              let getSiblings = (elem) => {
                // Setup siblings array and get the first sibling
                let siblings = [];
                let sibling = elem.parentNode.firstChild;
                // Loop through each sibling and push to the array
                while (sibling) {
                  if (sibling.nodeType === 1 && sibling !== elem) {
                    siblings.push(sibling);
                  }
                  sibling = sibling.nextSibling;
                }
                return siblings;
              };
              let siblings = getSiblings(collapse.parentElement);
              siblings.forEach((item) => {
                if (item.childNodes.length > 2)
                  item.firstElementChild.setAttribute("aria-expanded", "false");
                let ids = item.querySelectorAll("*[id]");
                ids.forEach((item1) => {
                  let aChild = item1.parentNode.firstChild;
                  if (aChild.hasAttribute("aria-expanded")){
                    aChild.setAttribute("aria-expanded", "false");
                  }
                  item1.classList.remove("show");
                  if (item1.childNodes.length > 2) {
                    let val = item1.querySelectorAll("ul li a");

                    val.forEach((subitem) => {
                      if (subitem.hasAttribute("aria-expanded"))
                        subitem.setAttribute("aria-expanded", "false");
                    });
                  }
                });
              });
            }
          });

          // Hide nested collapses on `hide.bs.collapse`
          collapse.addEventListener("hide.bs.collapse", (e) => {
            e.stopPropagation();
            let childCollapses = collapse.querySelectorAll(".collapse");
            childCollapses.forEach((childCollapse) => {
              let childCollapseInstance = childCollapse;
              childCollapseInstance.hide();
            });
          });
        });
      }
    },

    methods: {
      onRoutechange(ele) {
        this.initActiveMenu(ele.path);
        if (document.getElementsByClassName("mm-active").length > 0) {
          const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
          if (currentPosition > 500)
            if (this.$refs.isSimplebar)
              this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
        }
      },

      initActiveMenu(ele) {
        setTimeout(() => {
          if (document.querySelector("#navbar-nav")) {
            let a = document.querySelector("#navbar-nav").querySelector('[href="' + ele + '"]');

            if (a) {
              a.classList.add("active");
              let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
              if (parentCollapseDiv) {
                parentCollapseDiv.classList.add("show");
                parentCollapseDiv.parentElement.children[0].classList.add("active");
                parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
                if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                  parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
                  if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
                    parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add(
                      "active");
                }
              }
            }
          }
        }, 0);
      },
    },
  };
</script>

<template>
  <div class="container-fluid">
    
    <template v-if="layoutType === 'vertical'">
      <ul class="navbar-nav h-100" id="navbar-nav">
        <li class="menu-title">
          <span data-key="t-menu"> {{ $t("t-menu") }}</span>
        </li>
        <li class="nav-item">
          <a class="nav-link menu-link" href="#sidebarDashboards" data-bs-toggle="collapse" role="button"
            aria-expanded="false" aria-controls="sidebarDashboards">
            <i class="las la-tachometer-alt"></i>
            <span data-key="t-dashboards"> {{ $t("t-dashboards") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarDashboards">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link to="/dashboard/analytics" class="nav-link custom-abc" data-key="t-analytics">
                  {{ $t("t-analytics") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/dashboard/crm" class="nav-link" data-key="t-crm">
                  {{ $t("t-crm") }}
                </router-link>
              </li> 
              <li class="nav-item">
                <router-link to="/" class="nav-link" data-key="t-ecommerce">
                  {{ $t("t-ecommerce") }}
                </router-link> 
              </li>
             
              <li class="nav-item">
                <router-link to="/dashboard/projects" class="nav-link" data-key="t-projects">
                  {{ $t("t-projects") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <!-- end Dashboard Menu -->
       
        <li class="menu-title">
          <i class="ri-more-fill"></i>
          <span data-key="t-pages">{{ $t("t-module") }}</span>
        </li>
 
        <li v-for="(option, NIVEL1) in options" class="nav-item" :key="NIVEL1">
          <a class="nav-link menu-link" :href="'#'+option.NIVEL1" data-bs-toggle="collapse" role="button"
            aria-expanded="false" :aria-controls=option.NIVEL1>
            <i :class="option.Icono"></i>
            <span data-key="t-pages">{{ option.Description}}</span>
          </a>
          <div class="collapse menu-dropdown" :id=option.NIVEL1>
            <ul class="nav nav-sm flex-column">
              <li v-for="(level2, NIVEL2) in option.groups"  class="nav-item" :key="NIVEL2">
                <a  v-if="Object.keys(option.groups).length>1"  :href="'#link'+NIVEL2" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false"
                  :aria-controls="'link'+NIVEL2" :data-key="'link'+NIVEL2">{{ level2.NIVEL2 }}
                </a>
                <div  v-if="Object.keys(option.groups).length>1" class="collapse menu-dropdown" :id="'link'+NIVEL2">
                  <ul class="nav nav-sm flex-column">
                    <li v-for="(level3, FORMID) in level2.groups"  class="nav-item" :key="FORMID">
                        <router-link to="/pages/profile" class="nav-link" data-key="t-simple-page">
                          {{ level3.frmDescription }}
                        </router-link>
                    </li>
                  </ul>
                </div>
                <div  v-if="Object.keys(option.groups).length<2" >
                
                    <li v-for="(level3, FORMID) in level2.groups"  class="nav-item" :key="FORMID">
                        <router-link :to="'/'+option.NIVEL1+'/'+level3.FORMID" 
                           class="nav-link" data-key="t-simple-page">
                          {{ level3.frmDescription }}
                        </router-link>
                    </li>
            
                </div>

              </li>
            </ul>    
          </div>
        </li>

      </ul>
    </template>
  </div>
  <!-- Sidebar -->
</template>